const DazEthDefaultChains = {
    "0x1": {network: "MainNet", name: "Ethereum", networkId: 1},
    "0x4": {network: "Rinkeby", name: "Rinkeby", networkId: 4},
    "0x5": {network: "Goerli", name: "Goerli", networkId: 5},
    "0x89": {network: "Polygon", name: "Polygon", networkId: 137},
    "0x539": {network: "Internal", name: "Daz Internal", networkId: 1337},
    "0x53a": {network: "Local", name: "Daz Development", networkId: 1338},
};

function DazEth(network, config, nativeEvents = false, chains = DazEthDefaultChains) {
    this.network = network;
    this.chainId = null;
    this.ethEnabled = false;
    this.readOnly = false;
    this.selectedAccount = null;
    this.accounts = [];
    this.contract = null;
    this.owners = {};
    this.initEvents = false;
    this.provider = null;
    this.config = config ? config : {};
    this.chains = chains;
    this.nativeEvents = !!nativeEvents;
    this.chain = {};
}

DazEth.prototype.IsConnected = async function () {
    let provider = null;
    if (this.provider) {
        provider = this.provider;
    } else if (window.ethereum) {
        provider = window.ethereum;
    } else if (WalletConnectProvider && this.config.walletConnect) {
        const wc = new WalletConnectProvider.default(
            Object.assign({}, this.config.walletConnect, {qrcode: false})
        );
        try {
            wc.connector.on("display_uri", () => {
                throw 'not connected';
            });
            await wc.enable();
        } catch {
            return false;
        }
        provider = wc;
    }
    if (!provider) {
        return false;
    }
    let w3 = new Web3(provider);
    return (await w3.eth.getAccounts()).length > 0;
};


DazEth.prototype.Init = function () {
    if (window.ethereum) {
        this.InitBrowser();
    } else if (WalletConnectProvider && this.config.walletConnect) {
        this.InitWalletConnect();
    } else {
        this.Error(["no-wallet", null]);
    }
};

DazEth.prototype.InitBrowser = function () {
    if (window.ethereum) {
        this.provider = window.ethereum;
        this.__init();
    } else {
        this.Error(["no-wallet", null]);
    }
};

DazEth.prototype.InitWalletConnect = function () {
    if (WalletConnectProvider || !this.config.walletConnect) {
        const wc = new WalletConnectProvider.default(
            this.config.walletConnect
        );
        wc.enable().then((res) => {
            this.provider = wc;
            this.__init();
        }, (e) => {
            this.Error(["no-wallet", null]);
        }).catch(e => {
            this.Error(["no-wallet", null]);
        });
    } else {
        this.Error(["no-wallet", null]);
    }
}

DazEth.prototype.__init = function () {
    if (!this.provider) {
        this.Error(["no-wallet", null]);
        return;
    }
    this.provider.request({method: 'eth_requestAccounts'}).catch((e) => {
        throw "no-account";
    }).then((accounts) => {
        if (!accounts || accounts.length === 0) {
            throw "no-account";
        }
        if (!this.Enable()) {
            return;
        }
        if (!this.initEvents) {
            this.provider.on('accountsChanged', (accounts) => {
                console.info('accountsChanged event fired');
                window.dazeth.selectedAccount = accounts[0];
                this.triggerInit();
            });
            // If the network changes refresh the page...  Because that's what Metamask says to do.
            this.provider.on('chainChanged', (chainId) => {
                console.log(chainId, this.chains);
                if (!this.chains.hasOwnProperty(chainId)) {

                    this.Error(["wrong-network", this.chainId, Object.keys(this.chains)]);
                    this.chainId = chainId;
                    this.chain = null;
                    return;
                }

                if (this.chainId !== null && this.chainId !== chainId) {
                    this.Error(["chain-changed", chainId, Object.keys(this.chains)]);
                }
                const doInit = this.chainId === null;
                this.chainId = chainId;
                this.chain = this.chains[chainId];
                if (!this.ethEnabled) {
                    this.Enable();
                }
            });
            this.initEvents = true;
        }
    }).catch(e => {
        this.Error([e]);
    });
}

DazEth.prototype.SetContract = function (name, address) {
    if (!window.dazContracts[name]) {
        this.Error(['no-contract', name, address])
        return false;
    }
    this.contract = new this.web3.eth.Contract(window.dazContracts[name], address);
    return true;
}

DazEth.prototype.ChangeToChain = async function (id = this.GetInitializedChainID()) {
    return this.provider.request({
        method: 'wallet_switchEthereumChain',
        params: [
            {chainId: id}
        ],
    });
}

DazEth.prototype.AddNetwork = async function (network) {
    let data = {};
    switch (network) {
        case '0x89':
            data = {
                chainId: '0x89',
                rpcUrls: ['https://polygon-rpc.com/'],
                chainName: 'Polygon Mainnet',
                nativeCurrency: {name: 'MATIC', decimals: 18, symbol: 'MATIC'},
                blockExplorerUrls: ['https://polygonscan.com'],
            }
            break;
        default:
            return;
    }
    return this.provider.request({
        method: 'wallet_addEthereumChain',
        params: [
            data
        ],
    });
}

DazEth.prototype.GetInitializedChainID = function () {
    let defaultChain = null;
    for (let i in this.chains) {
        if (this.chains[i].default) {
            defaultChain = i;
        }
        if (this.chains[i].network !== this.network) {
            continue;
        }
        return i;
    }
    if (defaultChain) {
        return defaultChain;
    }
    throw('unknown network to change to ' + this.network);
}

DazEth.prototype.Error = function (data) {
    if (this.nativeEvents) {
        document.dispatchEvent(new CustomEvent("dazEthError", {detail: {error: data[0], data: data}}))
    } else {
        $(document).trigger('dazEthError', data);
    }
}

DazEth.prototype.Enable = async function () {
    if (this.ethEnabled) {
        return true;
    }
    if (this.provider) {
        this.web3 = new Web3(this.provider);
        window.web3 = this.web3;
        const chainId = await this.web3.eth.getChainId();
        const hexId = '0x' + parseInt(chainId, 10).toString(16);
        let found = false;
        this.chain = {};
        for (let i in this.chains) {
            if (i !== hexId) {
                continue;
            }
            this.chainId = i;
            found = true;
            break;
        }
        if (!found) {
            console.error('Wrong network selected');
            this.Error(["wrong-network", this.chainId, Object.keys(this.chains)]);
            this.ethEnabled = false;
            return;
        }
        let chain = this.chains[this.chainId];
        chain.id = this.chainId;
        if (this.network === null) {
            this.network = chain.network;
        }
        if (chain.network !== this.network) {
            console.error(chain.name + ' enabled in wallet, but app is not configured for the network');
            this.Error(["wrong-network", this.chainId, [chain.networkId]]);
            this.ethEnabled = false;
            return;
        }

        let account = await this.provider.request({method: 'eth_accounts'});
        if (account && account.length > 0) {
            this.selectedAccount = account[0];
            this.accounts = account;
        } else {
            this.Error(["no-account", null]);
            return false;
        }
        this.ethEnabled = true;
        this.chain = chain;
    } else {
        this.Error(["wrong-network", version, Object.keys(this.chains)]);
        this.ethEnabled = false;
    }
    if (this.ethEnabled) {
        setTimeout(() => {
            this.triggerInit();
        }, 50);
    }
    return this.ethEnabled;
}

DazEth.prototype.triggerInit = function() {
    if (this.nativeEvents) {
        document.dispatchEvent(new CustomEvent("dazEthInit"));
    } else {
        $(document).trigger('dazEthInit');
    }
}
