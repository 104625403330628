function DazEthUtil() {
}

DazEthUtil.prototype.toLower = (what) => {
    if (Array.isArray(what)) {
        let result = [];
        for (let i in what) {
            result[i] = what[i].toString().toLowerCase();
        }
        return result;
    }
    what = what.toString().toLowerCase();
    return what;
};

DazEthUtil.prototype.transactionScannerName = (hash) => {
    switch (dazeth.chainId) {
        case '0x1':
            return 'Etherscan';
        case '0x4':
            return 'Rinkeby Etherscan';
        case '0x89':
            return 'Polygonscan';
    }
}

DazEthUtil.prototype.transactionLink = (hash) => {
    switch (dazeth.chainId) {
        case '0x1':
            return 'https://etherscan.io/tx/' + hash
        case '0x4':
            return 'https://rinkeby.etherscan.io/tx/' + hash
        case '0x5':
            return 'https://goerli.etherscan.io/tx/' + hash
        case '0x89':
            return 'https://polygonscan.com/tx/' + hash;
    }
    return '#' + hash;
}

DazEthUtil.prototype.osAssetLink = (address, id) => {
    switch (dazeth.chainId) {
        case '0x1':
            return `https://opensea.io/assets/${address}/${id}`;
        case '0x4':
            return `https://testnets.opensea.io/assets/rinkeby/${address}/${id}`;
        case '0x89':
            return `https://opensea.io/assets/matic/${address}/${id}`;
    }
    return `#${address}:${id}`;
}

DazEthUtil.prototype.osUserLink = (address = dazethutil.me()) => {
    switch (dazeth.chainId) {
        case '0x4':
            return `https://testnets.opensea.io/${address}`;
    }
    return `https://opensea.io/${address}`;
}


DazEthUtil.prototype.hash = (hashIn) => {
    let toHash = hashIn.join('');
    return dazeth.web3.utils.keccak256("\x19Ethereum Signed Message:\n" + toHash.length.toString() + toHash);
}

DazEthUtil.prototype.hashAll = (hashIn) => {
    const hashIng = [];
    for (let i in hashIn) {
        hashIng[i] = dazethutil.hash([hashIn[i]])
    }
    return dazethutil.hash(hashIng);
}

DazEthUtil.prototype.me = function () {
    return dazethutil.toLower(window.dazeth.selectedAccount);
}

DazEthUtil.prototype.isValidAddress = function (address) {
    return address.match(/^0x[0-9a-f]{40}$/) !== null;
}

window.dazethutil = new DazEthUtil();

